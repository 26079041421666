/* Mixin */
#ContentPage #wrapper .EPiServerForms {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
#ContentPage #wrapper .EPiServerForms .Form__MainBody input[type=number]::-webkit-inner-spin-button,
#ContentPage #wrapper .EPiServerForms .Form__MainBody input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#ContentPage #wrapper .EPiServerForms .Form__MainBody input[type=number] {
  -moz-appearance: textfield;
}
#ContentPage #wrapper .EPiServerForms .Form__MainBody .FormStep__Title {
  font-size: 20px;
  padding: 20px 0 20px 0;
}
#ContentPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar {
  position: relative;
  left: 150px;
  width: 100%;
  padding-top: 30px;
}
@media (max-width: 450px) {
  #ContentPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar {
    left: auto;
  }
}
@media (max-width: 768px) {
  #ContentPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar {
    left: auto;
    text-align: center;
  }
}
#ContentPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar .Form__NavigationBar__ProgressBar {
  width: auto;
  border: none;
  padding: 0 10px 0 10px;
}
@media (max-width: 450px) {
  #ContentPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar .Form__NavigationBar__ProgressBar {
    padding: 10px 0 10px 0;
  }
}
#ContentPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar .Form__NavigationBar__ProgressBar .Form__NavigationBar__ProgressBar--Progress {
  display: none;
}
#ContentPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar .Form__NavigationBar__ProgressBar .Form__NavigationBar__ProgressBar--Text {
  display: block;
}
#ContentPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar .Form__NavigationBar__Action {
  background-color: #38424B;
  color: white;
  padding: 0 40px;
  font-size: 16px;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: bold;
  background-image: none;
  height: auto;
  width: auto;
  max-width: none;
  border-radius: 0px;
}
@media (max-width: 450px) {
  #ContentPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar .Form__NavigationBar__Action {
    width: 100%;
  }
}
#ContentPage #wrapper .EPiServerForms .Form__Title {
  margin: 30px 0 30px 0 !important;
}
#ContentPage #wrapper .EPiServerForms .Form__Description {
  font-size: 18px;
  margin-bottom: 30px;
}
#ContentPage #wrapper .EPiServerForms .Form__Status .Form__Success__Message {
  font-size: 16px;
  font-weight: 600;
  background-color: #FFFFFF;
}
#ContentPage #wrapper .EPiServerForms .Form__Element ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #878E92;
  opacity: 1;
  /* Firefox */
}
#ContentPage #wrapper .EPiServerForms .Form__Element ::-moz-placeholder {
  color: #878E92;
  opacity: 1;
}
#ContentPage #wrapper .EPiServerForms .Form__Element :-ms-input-placeholder {
  color: #878E92;
}
#ContentPage #wrapper .EPiServerForms .Form__Element ::-webkit-input-placeholder {
  color: #878E92;
}
#ContentPage #wrapper .EPiServerForms .Form__Element .ValidationRequired legend.Form__Element__Caption:after,
#ContentPage #wrapper .EPiServerForms .Form__Element .ValidationRequired label.Form__Element__Caption:after,
#ContentPage #wrapper .EPiServerForms .Form__Element .ValidationRequired fieldset label:first-child:after {
  content: " *";
  color: red;
}
#ContentPage #wrapper .EPiServerForms .Form__Element label.Form__Element__Caption {
  max-width: 150px;
  padding-right: 10px;
  min-width: 150px;
}
#ContentPage #wrapper .EPiServerForms .Form__Element fieldset {
  width: 100%;
}
#ContentPage #wrapper .EPiServerForms .Form__Element fieldset legend {
  font-size: 18px;
}
#ContentPage #wrapper .EPiServerForms .Form__Element .FormChoice label {
  display: block;
}
#ContentPage #wrapper .EPiServerForms .Form__Element .FormChoice__Input--Checkbox,
#ContentPage #wrapper .EPiServerForms .Form__Element .FormChoice__Input--Radio {
  width: 17px;
  height: 17px;
  margin-right: 10px;
  vertical-align: text-bottom;
}
#ContentPage #wrapper .EPiServerForms .Form__Element .FormTextbox--Textarea {
  align-items: flex-start !important;
}
#ContentPage #wrapper .EPiServerForms .Form__Element .FormTextbox,
#ContentPage #wrapper .EPiServerForms .Form__Element .FormSelection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#ContentPage #wrapper .EPiServerForms .Form__Element .FormTextbox__Input,
#ContentPage #wrapper .EPiServerForms .Form__Element .FormSelection__Input,
#ContentPage #wrapper .EPiServerForms .Form__Element .FormTextbox select,
#ContentPage #wrapper .EPiServerForms .Form__Element .FormSelection select {
  width: 370px;
}
#ContentPage #wrapper .EPiServerForms .Form__Element .FormTextbox--Textarea textarea,
#ContentPage #wrapper .EPiServerForms .Form__Element .FormSelection--Textarea textarea {
  padding: 10px 10px;
  width: 700px;
  height: 135px;
}
#ContentPage #wrapper .EPiServerForms .Form__Element .FormRecaptcha .g-recaptcha {
  position: relative;
  left: 150px;
}
#ContentPage #wrapper .EPiServerForms .Form__Element .FormSubmitButton {
  background-color: #D52B1E;
  border: none;
  color: #fff;
  padding: 0 40px;
  font-size: 16px;
  line-height: 60px;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 150px;
}
#ContentPage #wrapper .EPiServerForms .Form__Element__ValidationError {
  position: relative;
  left: 150px;
  width: 100%;
  padding-top: 5px;
}
@media (max-width: 768px) {
  #ContentPage #wrapper .EPiServerForms .Form__Element label.Form__Element__Caption {
    min-width: 30% !important;
    max-width: 30%;
  }
  #ContentPage #wrapper .EPiServerForms .Form__Element .FormRecaptcha .g-recaptcha {
    left: 30%;
  }
  #ContentPage #wrapper .EPiServerForms .Form__Element .FormSubmitButton {
    width: 35%;
    margin-left: 30%;
  }
  #ContentPage #wrapper .EPiServerForms .Form__Element__ValidationError {
    left: 30%;
  }
}
@media (max-width: 450px) {
  #ContentPage #wrapper .EPiServerForms .Form__Element label.Form__Element__Caption {
    max-width: none;
    padding-right: 0;
  }
  #ContentPage #wrapper .EPiServerForms .Form__Element .FormChoice label {
    margin-left: 0;
  }
  #ContentPage #wrapper .EPiServerForms .Form__Element .FormRecaptcha .g-recaptcha {
    left: auto;
  }
  #ContentPage #wrapper .EPiServerForms .Form__Element .FormSubmitButton {
    margin-left: 0;
    width: 100%;
  }
  #ContentPage #wrapper .EPiServerForms .Form__Element__ValidationError {
    left: auto;
  }
}
#ContentPage #wrapper .EPiServerForms .Form__SerialRow .fieldset-wrapper {
  display: flex;
  align-items: center;
}
#ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list .fieldset-item:first-of-type {
  width: 40%;
}
#ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list .fieldset-item:first-of-type .Form__Element__ValidationError {
  left: 150px;
}
#ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list .fieldset-item {
  width: 60%;
}
#ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list .fieldset-item .Form__Element {
  margin: 0 0 0 0;
}
#ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list .fieldset-item .Form__Element .FormTextbox__Input {
  width: 320px;
}
#ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list .fieldset-item .Form__Element__ValidationError {
  left: 150px;
}
#ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list .fieldset-item:first-of-type .Form__Element .FormTextbox__Input {
  width: 150px;
}
#ContentPage #wrapper .EPiServerForms .Form__SerialRow .FormTextbox__Input {
  margin-right: 10px;
}
@media (max-width: 768px) {
  #ContentPage #wrapper .EPiServerForms .Form__SerialRow .FormTextbox__Input:first-of-type {
    width: 370px;
  }
  #ContentPage #wrapper .EPiServerForms .Form__SerialRow .show_nextrow_btn {
    margin-top: 5px;
  }
  #ContentPage #wrapper .EPiServerForms .Form__SerialRow label,
  #ContentPage #wrapper .EPiServerForms .Form__SerialRow input {
    margin-bottom: 5px;
  }
}
@media (max-width: 768px) {
  #ContentPage #wrapper .EPiServerForms .Form__SerialRow {
    width: 100%;
  }
  #ContentPage #wrapper .EPiServerForms .Form__SerialRow .fieldset-wrapper {
    display: block;
  }
  #ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list .fieldset-item:first-of-type {
    width: 100%;
  }
  #ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list .fieldset-item:first-of-type .Form__Element__ValidationError {
    left: 30%;
  }
  #ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list .fieldset-item {
    width: 100%;
  }
  #ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list .fieldset-item .Form__Element .FormTextbox__Input {
    width: 370px;
  }
  #ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list .fieldset-item .Form__Element__ValidationError {
    left: 30%;
    margin-bottom: 5px;
  }
  #ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list .fieldset-item:first-of-type .Form__Element .FormTextbox__Input {
    width: 370px;
  }
}
@media (max-width: 450px) {
  #ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list .fieldset-item:first-of-type .Form__Element__ValidationError {
    left: 0px;
  }
  #ContentPage #wrapper .EPiServerForms .Form__SerialRow fieldset .fieldset-list .fieldset-item .Form__Element__ValidationError {
    left: 0px;
  }
}
#ContentPage #wrapper .EPiServerForms .underline_section {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}
#IndustryPage #wrapper .EPiServerForms .Form__MainBody input[type=number]::-webkit-inner-spin-button,
#IndustryPage #wrapper .EPiServerForms .Form__MainBody input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#IndustryPage #wrapper .EPiServerForms .Form__MainBody input[type=number] {
  -moz-appearance: textfield;
}
#IndustryPage #wrapper .EPiServerForms .Form__MainBody .FormStep__Title {
  font-size: 20px;
  padding: 20px 0 20px 0;
}
#IndustryPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar {
  position: relative;
  left: 0;
  width: 100%;
  padding-top: 30px;
}
@media (max-width: 450px) {
  #IndustryPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar {
    left: auto;
  }
}
@media (max-width: 768px) {
  #IndustryPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar {
    left: auto;
    text-align: center;
  }
}
#IndustryPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar .Form__NavigationBar__ProgressBar {
  width: auto;
  border: none;
  padding: 0 10px 0 10px;
}
@media (max-width: 450px) {
  #IndustryPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar .Form__NavigationBar__ProgressBar {
    padding: 10px 0 10px 0;
  }
}
#IndustryPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar .Form__NavigationBar__ProgressBar .Form__NavigationBar__ProgressBar--Progress {
  display: none;
}
#IndustryPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar .Form__NavigationBar__ProgressBar .Form__NavigationBar__ProgressBar--Text {
  display: block;
}
#IndustryPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar .Form__NavigationBar__Action {
  background-color: #38424B;
  color: white;
  padding: 0 40px;
  font-size: 16px;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: bold;
  background-image: none;
  height: auto;
  width: auto;
  max-width: none;
  border-radius: 0px;
}
@media (max-width: 450px) {
  #IndustryPage #wrapper .EPiServerForms .Form__MainBody .Form__NavigationBar .Form__NavigationBar__Action {
    width: 100%;
  }
}
#IndustryPage #wrapper .EPiServerForms .Form__Title {
  font-size: 24px;
  margin: 30px 0 30px 0 !important;
}
#IndustryPage #wrapper .EPiServerForms .Form__Description {
  font-size: 18px;
  margin-bottom: 30px;
}
#IndustryPage #wrapper .EPiServerForms .Form__Status .Form__Success__Message {
  font-size: 16px;
  font-weight: 600;
  background-color: #FFFFFF;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #878E92;
  opacity: 1;
  /* Firefox */
}
#IndustryPage #wrapper .EPiServerForms .Form__Element ::-moz-placeholder {
  color: #878E92;
  opacity: 1;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element :-ms-input-placeholder {
  color: #878E92;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element ::-webkit-input-placeholder {
  color: #878E92;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element .ValidationRequired legend.Form__Element__Caption:after,
#IndustryPage #wrapper .EPiServerForms .Form__Element .ValidationRequired label.Form__Element__Caption:after,
#IndustryPage #wrapper .EPiServerForms .Form__Element .ValidationRequired fieldset label:first-child:after {
  content: " *";
  color: red;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element .ValidationRequired legend.Form__Element__Caption:empty:after,
#IndustryPage #wrapper .EPiServerForms .Form__Element .ValidationRequired label.Form__Element__Caption:empty:after,
#IndustryPage #wrapper .EPiServerForms .Form__Element .ValidationRequired fieldset label:first-child:empty:after {
  content: "";
}
#IndustryPage #wrapper .EPiServerForms .Form__Element label.Form__Element__Caption:empty {
  min-width: 0px;
  padding-right: 0px;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element label.Form__Element__Caption {
  padding-right: 10px;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element fieldset {
  width: 100%;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element fieldset legend {
  font-size: 18px;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element .FormChoice label {
  display: block;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element .FormChoice__Input--Checkbox,
#IndustryPage #wrapper .EPiServerForms .Form__Element .FormChoice__Input--Radio {
  width: 17px;
  height: 17px;
  margin-right: 10px;
  vertical-align: text-bottom;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element .FormTextbox--Textarea {
  align-items: flex-start !important;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element .FormTextbox,
#IndustryPage #wrapper .EPiServerForms .Form__Element .FormSelection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element .FormTextbox__Input,
#IndustryPage #wrapper .EPiServerForms .Form__Element .FormSelection__Input,
#IndustryPage #wrapper .EPiServerForms .Form__Element .FormTextbox select,
#IndustryPage #wrapper .EPiServerForms .Form__Element .FormSelection select {
  width: 370px;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element .FormTextbox--Textarea textarea,
#IndustryPage #wrapper .EPiServerForms .Form__Element .FormSelection--Textarea textarea {
  padding: 10px 10px;
  width: 700px;
  height: 135px;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element .FormRecaptcha .g-recaptcha {
  position: relative;
  left: 0;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element .FormSubmitButton {
  background-color: #D52B1E;
  border: none;
  color: #fff;
  padding: 0 40px;
  font-size: 16px;
  line-height: 60px;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 0;
}
#IndustryPage #wrapper .EPiServerForms .Form__Element__ValidationError {
  position: relative;
  left: 0;
  width: 100%;
  padding-top: 5px;
}
@media (max-width: 768px) {
  #IndustryPage #wrapper .EPiServerForms .Form__Element label.Form__Element__Caption {
    min-width: 0;
  }
  #IndustryPage #wrapper .EPiServerForms .Form__Element .FormChoice label {
    margin-left: 0;
  }
  #IndustryPage #wrapper .EPiServerForms .Form__Element .FormRecaptcha .g-recaptcha {
    left: 0;
  }
  #IndustryPage #wrapper .EPiServerForms .Form__Element .FormSubmitButton {
    width: 35%;
    margin-left: 0;
  }
  #IndustryPage #wrapper .EPiServerForms .Form__Element__ValidationError {
    left: 0;
  }
}
@media (max-width: 560px) {
  #IndustryPage #wrapper .EPiServerForms .Form__Element .FormRecaptcha .g-recaptcha {
    left: auto;
  }
  #IndustryPage #wrapper .EPiServerForms .Form__Element .FormSubmitButton {
    margin-left: 0;
  }
  #IndustryPage #wrapper .EPiServerForms .Form__Element__ValidationError {
    left: auto;
  }
}
@media (max-width: 450px) {
  #IndustryPage #wrapper .EPiServerForms .Form__Element label.Form__Element__Caption {
    max-width: none;
    padding-right: 0;
  }
  #IndustryPage #wrapper .EPiServerForms .Form__Element .FormChoice label {
    margin-left: 0;
  }
  #IndustryPage #wrapper .EPiServerForms .Form__Element .FormRecaptcha .g-recaptcha {
    left: auto;
  }
  #IndustryPage #wrapper .EPiServerForms .Form__Element .FormSubmitButton {
    margin-left: 0;
    width: 100%;
  }
  #IndustryPage #wrapper .EPiServerForms .Form__Element__ValidationError {
    left: auto;
  }
}
.hasOverlay {
  position: relative;
}
.hasOverlay:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
}
@media (max-width: 768px) {
  body > #wrapper {
    max-width: 100vw;
    overflow: hidden;
  }
}
.initial-logo {
  display: none !important;
  height: 0 !important;
}

